<template>
  <div id="footer" class="footer">
    <div class="footer__container">
      <div class="footer__container__row">
        <!-- Alfa -->
        <div class="footer__container__row__column footer__container__row__column--alfa">
          <i class="footer__container__row__column__icon">
            <IconConsignadoLogoWhite />
          </i>

          <ul class="footer__container__row__column__list">
            <li >FINANCEIRA ALFA S. A. CRÉDITO</li>
            <li>FINANCIAMENTO E INVESTIMENTOS</li>
            <li>CNPJ: 17.167.412/0001-13</li>
          </ul>

          <p class="footer__container__row__column__paragraph">
            Nossas redes sociais:
          </p>

          <ul class="footer__container__row__column__social">
            <li>
              <a href="https://www.facebook.com/financeiraalfa">
                <IconFacebookFooter />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/financeiraalfaoficial/"  >
                <IconInstagramFooter />
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/showcase/financeira-alfa" >
                <IconLinkedinFooter />
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/@AlfaConglomerado" >
                <IconYoutubeFooter />
              </a>
            </li>
          </ul>
        </div>

        <!-- Localização -->
        <div class="footer__container__row__column footer__container__row__column--location">
          <span class="footer__container__row__column__name">
            Localização
            <IconHouse />
          </span>

          <ul class="footer__container__row__column__list">
            <li v-for="(item, id) in location" :key="id">
              {{ item }}
            </li>
          </ul>
        </div>

        <!-- Contato -->
        <div class="footer__container__row__column footer__container__row__column--contact">
          <span class="footer__container__row__column__name">
            Informações de Contato
            <IconPhone />
          </span>

          <span class="footer__container__row__column__info">
            <h2>SAC Financeira Alfa</h2>
            <p>dias úteis, das 8h às 18h</p>
          </span>

          <ul class="footer__container__row__column__list">
            <li v-for="(item, id) in contact" :key="id">
              <a :href="item.link">{{ item.value }}</a> <span>{{ item.text }}</span>
            </li>
          </ul>
        </div>

        <!-- Links rápidos -->
        <div class="footer__container__row__column footer__container__row__column--links">
          <span class="footer__container__row__column__name">
            Links Rápidos
          </span>

          <ul class="footer__container__row__column__list">
            <li v-for="(item, id) in links" :key="id">
              <a 
                @click.prevent="redirectTo(item.link, item.isExternal)"
                :href="item.link"
                :target="item.isExternal ? '_blank' : '_self'"
              >{{ item.text }}</a>
            </li>
          </ul>
        </div>

        <!-- Regulamentos -->
        <div class="footer__container__row__column footer__container__row__column--regulation">
          <span class="footer__container__row__column__name">
            Regulamentos
          </span>

          <ul class="footer__container__row__column__list">
            <li v-for="(item, id) in regulation" :key="id">
              <a 
                @click.prevent="redirectTo(item.link, item.isExternal)"
                :href="item.link"
                :target="item.isExternal ? '_blank' : '_self'"
              >{{ item.text }}</a>
            </li>
          </ul>
        </div>
      </div>

      <div class="footer__container__row">
        <!-- Copyright -->
        <div class="footer__container__row__column footer__container__row__column--copyright">
          <p class="footer__container__row__column__paragraph">
            Copyright {{ year }} - Banco Alfa Todos os direitos reservados
          </p>
        </div>
      </div>
    </div>

    <div class="footer__container">
      <div class="footer__container__row">
        <!-- Texto "Legal"/"Importante" -->
        <div class="footer__container__row__column footer__container__row__column--legaly">
          <span class="footer__container__row__column__name">
            IMPORTANTE
          </span>
          
          <p class="footer__container__row__column__paragraph">
            Este site - www.alfaconsignado.com.br - pertence à Financeira Alfa S.A. - CFI e/ou suas Afiliadas.
          </p>

          <p class="footer__container__row__column__paragraph">
            As operações de empréstimo consignado iniciadas e/ou realizadas por meio deste site não se utilizam 
            de Correspondentes no País.
          </p>

          <p class="footer__container__row__column__paragraph">
            A contratação de referida operação de empréstimo está sujeita a análise cadastral, margem consignável 
            disponível, aprovação de crédito e existência de convênio entre a Financeira Alfa e o respectivo 
            órgão/empresa empregadora. As parcelas da operação de empréstimo consignado serão debitadas da 
            folha de pagamento/benefício do tomador do crédito. As taxas de juros praticadas seguem regras 
            definidas e estão de acordo com a política de crédito da Financeira Alfa. A Financeira Alfa, de 
            acordo com a legislação vigente, disponibilizará previamente à eventual contratação, todas as 
            informações necessárias, de forma transparente, incluindo a taxa de juros praticada, eventuais 
            tarifas aplicáveis, encargos e demais impostos incidentes, bem como o custo efetivo total (CET) 
            da operação. O atraso no pagamento de qualquer valor devido, sujeitará o cliente a, dentre outras 
            medidas, ter incluído seu nome no cadastro de inadimplentes dos órgãos de proteção ao crédito, 
            o protesto do respectivo título e/ou, ainda, o ajuizamento de ações cabíveis de cobrança.
          </p>

          <p class="footer__container__row__column__paragraph">
            Mantenha seus dados cadastrais atualizados e, em havendo alguma alteração, comunicar à Financeira 
            Alfa.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// Dependencias
import { useRoute } from 'vue-router'

// Icones
import IconConsignadoLogoWhite from '../icons/IconConsignadoLogoWhite.vue'
import IconHouse from '../icons/IconHouse.vue'
import IconPhone from '../icons/IconPhone.vue'
import IconFacebookFooter from '../icons/IconFacebookFooter.vue'
import IconInstagramFooter from '../icons/IconInstagramFooter.vue'
import IconYoutubeFooter from '../icons/IconYoutubeFooter.vue'
import IconLinkedinFooter from '../icons/IconLinkedinFooter.vue'

// Propriedades
const date = new Date()
const year = date.getFullYear()
const route = useRoute()
const location = [
  'Avenida Paulista, 2.150, 17º andar',
  'Bela Vista, São Paulo – SP',
  'CEP 01310-300'
]
const contact = [
  {
    link: 'tel:40040044',
    value: '4004 0044',
    text: '(capitais e regiões metropolitanas)'
  },
  {
    link: 'tel:08007250044',
    value: '0800 725 0044',
    text: '(demais localidades)'
  },
  {
    link: 'https://wa.me/551133868659',
    value: '(11) 3386 8659',
    text: '(WhatsApp)'
  }
]
const links = [
  {
    link: '/',
    text: 'Página Inicial',
    isExternal: false,
  },
  {
    link: '/vantagens-e-diferenciais',
    text: 'Vantagens e Diferenciais',
    isExternal: false,
  },
  {
    link: '/quem-somos',
    text: 'Quem Somos',
    isExternal: false,
  },
  {
    link: '/blog',
    text: 'Blog',
    isExternal: false,
  },
  {
    link: '/ajuda',
    text: 'Ajuda',
    isExternal: false,
  },
  {
    link: '/termos-de-uso',
    text: 'Termos de Uso',
    isExternal: false,
  },
  {
    link: '/politica-de-privacidade',
    text: 'Política de Privacidade',
    isExternal: false,
  },
  {
    link: '/lgpd',
    text: 'LGPD',
    isExternal: false,
  },
  {
    link: '/alerta-antifraude',
    text: 'Alerta Antifraude',
    isExternal: false,
  }
]
const regulation = [
  {
    link: getAsset('regulamento-inss.pdf'),
    text: 'INSS',
    isExternal: true
  },
  {
    link: getAsset('regulamento-publico.pdf'),
    text: 'Público',
    isExternal: true
  },
  {
    link: getAsset('regulamento-privado.pdf'),
    text: 'Privado',
    isExternal: true
  } 
]

// Metodos
function redirectTo(link, isExternal = false) {
  if (route.path === link) {
    window.location.href = link
  } else {
    navigateTo(link, {
      external: isExternal,
      open: { target: isExternal ? '_blank' : '_self' }
    })
  }
}

function getAsset(path) {
  const glob = import.meta.glob('~/assets/documents/*', {
    eager: true,
  })
  return glob[`/assets/documents/${path}`]['default']
}
</script>

<style lang="scss" scoped>
.footer {
  display: grid;
  grid-auto-flow: row;
  place-content: start stretch;
  
  &__container {
    display: grid;
    grid-auto-flow: row;
    place-items: start center;
    padding: 40px 60px;
    row-gap: 32px;

    &:first-of-type {
      background-color: $alfa-primary;
    }

    @media screen and (max-width: 940px) {
      row-gap: 40px;

      &:first-of-type > &__row {
        grid-auto-flow: row;
        row-gap: 40px;
        
        &:first-of-type {
          place-content: start;
        }
      }
    }

    &:last-of-type {
      background-color: $alfa-white;
    }

    &__row {
      display: grid;
      grid-auto-flow: column;
      place-content: start stretch;
      column-gap: 24px;
      width: 100%;
      max-width: 1665px;

      &__column {
        display: grid;
        grid-auto-flow: row;
        place-content: start stretch;
        row-gap: 20px;

        &__icon {
          max-width: 130px;
          max-height: 60px;

          svg {
            display: block;
            width: 100%;
            height: 60px;
          }
        }
        
        &__name {
          display: grid;
          grid-auto-flow: column;
          column-gap: 10px;
          place-content: start;
        }

        &--location > &__name,
        &--contact > &__name,
        &--links > &__name,
        &--regulation > &__name {
          @include font($montserrat-regular, 16, 1.5vw, 16, $alfa-secondary);

          svg {
            display: inline-block;
            max-width: 25px;
            width: 100%;
            height: 100%;
          }
        }

        &--legaly > &__name {
          @include font($montserrat-bold, 16, 1.5vw, 16, $alfa-primary);
        }

        &__info {
          display: grid;
          place-content: start;

          & > h2 {
            @include font($montserrat-bold, 16, 1.5vw, 16, $alfa-white);
          }
          
          & > p {
            @include font($montserrat-regular, 16, 1.5vw, 16, $alfa-white);
          }
        }

        &__list {
          display: grid;
          place-content: start stretch;
        }

        &--alfa > &__list {
          row-gap: 8px;

          & > li {
            &:not(:last-of-type) {
              @include font($montserrat-regular, 16, 1.5vw, 16, $alfa-white);
            }

            &:last-of-type {
              @include font($montserrat-light, 16, 1.5vw, 16, $alfa-white);
            }
          }
        }

        &--location > &__list,
        &--contact > &__list {
          row-gap: 8px;
          padding-left: 30px;
          position: relative;
          
          &::before {
            content: '';
            width: 12px;
            height: 12px;
            background-color: $alfa-secondary;
            border-radius: 100%;
            position: absolute;
            top: 5px;
            left: 0px;
          }

          & > li,
          & > li a,
          & > li span {
            @include font($montserrat-light, 16, 1.5vw, 16, $alfa-white);
          }

          & > li a {
            text-decoration: underline;
          }
        }

        &--links > &__list,
        &--regulation > &__list {
          grid-auto-flow: row;
          row-gap: 8px;
          
          & > li a {
            text-decoration: none;
            @include font($montserrat-light, 16, 1.5vw, 16, $alfa-white);
          }
        }

        &__paragraph {
          display: grid;
          place-content: start stretch;
        }

        &--alfa > &__paragraph {
          @include font($montserrat-thin, 16, 1.5vw, 16, $alfa-sec);
        }

        &--copyright > &__paragraph {
          @include font($montserrat-light, 16, 1.5vw, 16, $alfa-sec, center);
        }

        &--legaly > &__paragraph {
          @include font($montserrat-regular, 16, 1.5vw, 16, $alfa-default-text);
        }

        &__social {
          display: grid;
          grid-auto-flow: column;
          place-content: start;
          place-items: center;
          column-gap: 10px;

          & > li {
            max-width: 30px;
            max-height: 30px;

            & > a {
              display: block;
              width: 100%;
              height: 30px;

              svg {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
